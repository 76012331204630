import React from "react";
import styled from "@emotion/styled";
import { Color } from "../utils/color";
import { Link } from "gatsby";

import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs";
import { Layout } from "../components/layout/layout";

const RecruitPage = () => {
  return (
    <Layout title="採用情報 | LIME" jpUrl="/recruit/" enUrl="/en/recruit/">
      <Breadcrumbs>
        <Link to="/">ホーム</Link>
        <span>/</span>
        <Link to="/recruit/">採用情報</Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <p>採用情報</p>
      </SSection>
    </Layout>
  );
};

export default RecruitPage;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
